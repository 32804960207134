/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { constants } from './models/autoupdate/constants';
import {
  UPDATE_JOB_PROGRESS_FAILED,
  UPDATE_JOB_PROGRESS_PARTIALLY_SUCCEEDED,
  UPDATE_JOB_PROGRESS_READY,
  UPDATE_JOB_PROGRESS_RUNNING,
  UPDATE_JOB_PROGRESS_SUCCEEDED,
  UPDATE_JOB_DEVICE_PROGRESS_FAILED,
  UPDATE_JOB_DEVICE_PROGRESS_RETRYING,
  UPDATE_JOB_DEVICE_PROGRESS_RUNNING,
  UPDATE_JOB_DEVICE_PROGRESS_SUBMITTING,
  UPDATE_JOB_DEVICE_PROGRESS_SUCCEEDED,
  UPDATE_JOB_DEVICE_PROGRESS_TIMEOUT,
  UPDATE_JOB_DEVICE_PROGRESS_UNKNOWN,
  UPDATE_JOB_DEVICE_RESULT_ERROR,
  UPDATE_JOB_DEVICE_RESULT_ERROR_BASE_VERSION_MISSING,
  UPDATE_JOB_DEVICE_RESULT_ERROR_BUSY,
  UPDATE_JOB_DEVICE_RESULT_ERROR_DECRYPTION,
  UPDATE_JOB_DEVICE_RESULT_ERROR_DISK_SPACE,
  UPDATE_JOB_DEVICE_RESULT_ERROR_DOWNLOAD,
  UPDATE_JOB_DEVICE_RESULT_ERROR_FILESYSTEM,
  UPDATE_JOB_DEVICE_RESULT_ERROR_FILE_CORRUPT,
  UPDATE_JOB_DEVICE_RESULT_ERROR_KERNEL_FAILED,
  UPDATE_JOB_DEVICE_RESULT_ERROR_MANIFEST_MISMATCH,
  UPDATE_JOB_DEVICE_RESULT_ERROR_UNIMPLEMENTED,
  UPDATE_JOB_DEVICE_RESULT_IGNORED,
  UPDATE_JOB_DEVICE_RESULT_SUCCESS,
  DEPLOYMENT_METHOD_AUTO_UPDATE,
  DEPLOYMENT_METHOD_UPDATE_JOB,
  UPDATE_JOB_DEVICE_PROGRESS_SOFTWARE_UPDATE,
  UPDATE_JOB_DEVICE_RESULT_ERROR_TIMEOUT
} from './models/rollouts/constants';
import { i18n } from './plugins/i18n';
import { colors } from '@/styles/colors';

// TODO: PLACE USED DICTS TO OWN FILE (Maybe even bundle with teh constants file...)
// TODO: TYPE USE DICTS AND FUNCTIONS

export const filters = {

  beautifyResourceFileType: (value: string) =>
    ({
      [constants.RESOURCE_FILE_TYPE_FULL_SOFTWARE_UPDATE]:
        'Full Software Update',
      [constants.RESOURCE_FILE_TYPE_INCREMENTAL_SOFTWARE_UPDATE]:
        'Incr. Software Update',
      [constants.RESOURCE_FILE_TYPE_DSL_UPDATE]: 'DSL Update',
      [constants.RESOURCE_FILE_TYPE_CONTAINER]: 'Container',
      [constants.RESOURCE_FILE_TYPE_CONTAINER_CONFIGURATION]:
        'Container-Configuration',
      [constants.RESOURCE_FILE_TYPE_LICENCE]: 'License',
      [constants.RESOURCE_FILE_TYPE_BINARY_CONFIGURATION]:
        'Binary-Configuration',
      [constants.RESOURCE_FILE_TYPE_ASCII_CONFIG]: 'ASCII-Configuration',
      [constants.RESOURCE_FILE_TYPE_STORED_ASCII_CONFIG]:
        'Stored ASCII-Configuration',
      [constants.RESOURCE_FILE_TYPE_ASCII_CONFIG_ICS]:
        'ASCII-Configuration ICS',
      [constants.RESOURCE_FILE_TYPE_OEM_BRANDING]: 'OEM Branding'
    })[value] || '',

  beautifyUpdateJobProgress: (status: string) =>
    ({
      [UPDATE_JOB_PROGRESS_READY]: `${i18n.t('main.progressReady')}`,
      [UPDATE_JOB_PROGRESS_RUNNING]: `${i18n.t('main.active')}`,
      [UPDATE_JOB_PROGRESS_SUCCEEDED]: `${i18n.t('main.progressSucceeded', 1)}`,
      [UPDATE_JOB_PROGRESS_PARTIALLY_SUCCEEDED]: `${i18n.t('main.progressPartiallySucceeded')}`,
      [UPDATE_JOB_PROGRESS_FAILED]: `${i18n.t('main.failed', 1)}`
    })[status] || '',

  beautifyUpdateTemplateStatus: (status: string) =>
    ({
      [constants.CONFIG_CHANGE_TEMPLATE_STATUS_AVAILABLE]: `${i18n.t('templates.statusAvailable')}`,
      [constants.CONFIG_CHANGE_TEMPLATE_STATUS_LINKED]: `${i18n.t('templates.statusLinked')}`
    })[status] || `${i18n.t('templates.statusUnknown')}`,

  updateJobProgressColor: (value: string) =>
    ({
      [UPDATE_JOB_PROGRESS_READY]: colors.darkShadow,
      [UPDATE_JOB_PROGRESS_RUNNING]: colors.primary,
      [UPDATE_JOB_PROGRESS_SUCCEEDED]: colors.success,
      [UPDATE_JOB_PROGRESS_PARTIALLY_SUCCEEDED]: colors.warning,
      [UPDATE_JOB_PROGRESS_FAILED]: colors.danger
    })[value] || colors.darkShadow,

  updateJobProgressEmoticon: (value: string) =>
    ({
      [UPDATE_JOB_PROGRESS_SUCCEEDED]: 'mdi-emoticon-happy',
      [UPDATE_JOB_PROGRESS_PARTIALLY_SUCCEEDED]: 'mdi-emoticon-sad',
      [UPDATE_JOB_PROGRESS_FAILED]: 'mdi-emoticon-sad'
    })[value] || '',

  beautifyUpdateJobDeviceProgress: (value: string) =>
    ({
      [UPDATE_JOB_DEVICE_PROGRESS_RETRYING]: `${i18n.t('main.progressRetrying')}`,
      [UPDATE_JOB_DEVICE_PROGRESS_SUBMITTING]: `${i18n.t('main.progressSubmitting')}`,
      [UPDATE_JOB_DEVICE_PROGRESS_RUNNING]: `${i18n.t('main.active')}`,
      [UPDATE_JOB_DEVICE_PROGRESS_SUCCEEDED]: `${i18n.t('main.progressSucceeded', 2)}`,
      [UPDATE_JOB_DEVICE_PROGRESS_FAILED]: `${i18n.t('main.failed', 2)}`,
      [UPDATE_JOB_DEVICE_PROGRESS_TIMEOUT]: `${i18n.t('main.progressTimeout')}`,
      [UPDATE_JOB_DEVICE_PROGRESS_UNKNOWN]: `${i18n.t('main.progressUnknown')}`,
      [UPDATE_JOB_DEVICE_PROGRESS_SOFTWARE_UPDATE]: `${i18n.t('main.softwareUpdate')}`
    })[value] || `${i18n.t('main.progressUnknown')}`,

  updateJobDeviceProgressColor: (value: string) =>
    ({
      [UPDATE_JOB_DEVICE_PROGRESS_RETRYING]: colors.darkShadow,
      [UPDATE_JOB_DEVICE_PROGRESS_SUBMITTING]: colors.secondary,
      [UPDATE_JOB_DEVICE_PROGRESS_RUNNING]: colors.primary,
      [UPDATE_JOB_DEVICE_PROGRESS_SUCCEEDED]: colors.success,
      [UPDATE_JOB_DEVICE_PROGRESS_FAILED]: colors.danger,
      [UPDATE_JOB_DEVICE_PROGRESS_TIMEOUT]: colors.danger,
      [UPDATE_JOB_DEVICE_PROGRESS_SOFTWARE_UPDATE]: colors.primary,
      [UPDATE_JOB_DEVICE_PROGRESS_UNKNOWN]: colors.darkShadow
    })[value] || colors.darkShadow,

  updateJobDeviceProgressReason: (value: string) =>
    ({
      [UPDATE_JOB_DEVICE_RESULT_SUCCESS]: i18n.t('main.resultSuccess'),
      [UPDATE_JOB_DEVICE_RESULT_IGNORED]: i18n.t('main.resultIgnored'),
      [UPDATE_JOB_DEVICE_RESULT_ERROR]: i18n.t('main.resultError'),
      [UPDATE_JOB_DEVICE_RESULT_ERROR_UNIMPLEMENTED]: i18n.t(
        'main.resultErrorUnimplemented'
      ),
      [UPDATE_JOB_DEVICE_RESULT_ERROR_BUSY]: i18n.t('main.resultErrorBusy'),
      [UPDATE_JOB_DEVICE_RESULT_ERROR_DISK_SPACE]: i18n.t(
        'main.resultErrorDiskSpace'
      ),
      [UPDATE_JOB_DEVICE_RESULT_ERROR_FILE_CORRUPT]: i18n.t(
        'main.resultErrorFileCorrupt'
      ),
      [UPDATE_JOB_DEVICE_RESULT_ERROR_BASE_VERSION_MISSING]: i18n.t(
        'main.resultErrorBaseVersionMissing'
      ),
      [UPDATE_JOB_DEVICE_RESULT_ERROR_DECRYPTION]: i18n.t(
        'main.resultErrorDecryption'
      ),
      [UPDATE_JOB_DEVICE_RESULT_ERROR_MANIFEST_MISMATCH]: i18n.t(
        'main.resultErrorManifestMismatch'
      ),
      [UPDATE_JOB_DEVICE_RESULT_ERROR_KERNEL_FAILED]: i18n.t(
        'main.resultErrorKernelFailed'
      ),
      [UPDATE_JOB_DEVICE_RESULT_ERROR_FILESYSTEM]: i18n.t(
        'main.resultErrorFileSystem'
      ),
      [UPDATE_JOB_DEVICE_RESULT_ERROR_DOWNLOAD]: i18n.t(
        'main.resultErrorDownload'
      ),
      [UPDATE_JOB_DEVICE_RESULT_ERROR_TIMEOUT]: i18n.t(
        'main.resultErrorTimeout'
      )
    })[value] || i18n.t('main.resultError'),

  beautifyDeviceCount: (value: number) =>
    value === 1
      ? `${i18n.t('common.oneDevice')}`
      : `${value} ${i18n.t('common.devices')}`,

  beautifyDeploymentMethod: function (value: string) {
    switch (value) {
      case DEPLOYMENT_METHOD_AUTO_UPDATE:
        return `${i18n.t('updateWizard.autoUpdate')}`;
      case DEPLOYMENT_METHOD_UPDATE_JOB:
        return `${i18n.t('updateWizard.updateJob')}`;
    }
  },
  truncate: (text: string, length: number, clamp?: string) => {
    clamp = clamp || '...';
    const node = document.createElement('div');
    node.innerHTML = text;
    const content = node.textContent;
    return content && content.length > length
      ? content?.slice(0, length) + clamp
      : content;
  },
  getGraphqlUrlHTTP: () => {
    let url = import.meta.env.VITE_API_GRAPHQL_HTTPS_URL;
    if (!url) {
      url = import.meta.env.VITE_API_GRAPHQL_HTTP_URL;
    }
    return fixGraphqlUrl(url, false);
  },
  getGraphqlUrlWS: () => {
    let url = import.meta.env.VITE_API_GRAPHQL_WSS_URL;
    if (!url) {
      url = import.meta.env.VITE_API_GRAPHQL_WS_URL;
    }
    return fixGraphqlUrl(url, true);
  },
  assetUrl: (value: string) => `${import.meta.env.BASE_URL || '/'}${value}`
} as const;


function fixGraphqlUrl(value: string, websocket: boolean) {
  const locationProtocol = window.location.protocol.split(':')[0];
  const isTLS = locationProtocol === 'https';
  let prefix = isTLS ? 'wss' : 'ws';
  if (!websocket) {
    prefix = isTLS ? 'https' : 'http';
  }

  if (value.indexOf('://') !== -1) {
    return value;
  } else if (value.startsWith('/')) {
    return `${prefix}://${window.location.hostname}${value}`;
  } else {
    return `${prefix}://${value}`;
  }
}
