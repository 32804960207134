/* eslint-disable prefer-arrow/prefer-arrow-functions */
import {
  AuditLogActionType,
  AuditLogStateType,
  AuditLogType
} from '@/store/types/audit';
import { findLogs, exportLogs } from '@/services/audit/LogApi';
import { createErrorMessage, showApiErrorSnackbar } from '@/util/snackBarUtil';
import { downloadFile } from '@/util/downloadUtil';

export default {
  namespaced: true,

  state: {
    logs: []
  },

  getters: {
    logs: (state: AuditLogStateType) => state.logs
  },

  actions: {
    async load({ commit }: AuditLogActionType) {
      try {
        const response = await findLogs();
        commit('ADD_LOGS', response.data.members);
      } catch (error) {
        showApiErrorSnackbar(createErrorMessage(error), error);
      }
    },
    download: async () => {

      try {
        const response = await exportLogs();
        const fileName = 'auditLogs.csv';
        downloadFile(response.data, fileName);
      } catch (error) {
        showApiErrorSnackbar(createErrorMessage(error), error);
      }
    }
  },

  mutations: {
    ['ADD_LOGS'](state: AuditLogStateType, payload: AuditLogType[]) {
      payload.forEach((log: AuditLogType) => {
        const index = state.logs.findIndex(
          (existing) => existing.uuid === log.uuid
        );
        if (index === -1) {
          state.logs.push(log);
        } else {
          state.logs.splice(index, 1, log);
        }
      });

      state.logs = payload.sort((a, b) =>
        a.createdAt && b.createdAt
          ? a.createdAt < b.createdAt
            ? 1
            : b.createdAt < a.createdAt
              ? -1
              : 0
          : 0
      );
    }
  }
};
