<template>
  <v-dialog
    v-model="show"
    persistent
    scrollable
    :width="overrideWidth ?? config.dialogWidth"
  >
    <v-card class="rounded-corners">
      <v-toolbar flat density="compact" class="pa-3" color="transparent">
        <v-toolbar-title class="bold-text">
          {{
            $t(
              config.title,
              {
                count: titleCounter,
                msg: titleMsg
              },
              titleCounter
            )
          }}
        </v-toolbar-title>
        <v-btn
          v-if="!processAction"
          class="ma-0 pa-0"
          icon
          data-cy="x-close-button"
          @click="closeDialog()"
        >
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pa-0 ma-0">
        <slot />
      </v-card-text>
      <v-divider />
      <v-card-actions class="pa-0 ma-0">
        <v-container v-if="processAction" class="warning-card">
          <v-progress-circular
            indeterminate
            size="32"
            width="4"
            color="black"
          />
          <span class="pl-4">{{
            $t(config.processText ?? 'common.wait', titleCounter)
          }}</span>
        </v-container>
        <v-container v-else class="ml-6 mr-3">
          <v-row v-if="simpleInformationDialog" align="center" justify="end">
            <v-btn
              data-cy="ok-button"
              variant="outlined"
              color="primary"
              class="text-none my-4"
              @click="clickButton(ButtonCallbacks.OK)"
            >
              {{ $t('common.ok') }}
            </v-btn>
          </v-row>
          <v-row v-else align="center">
            <v-checkbox
              v-if="$attrs['show-action-flag']"
              v-model:model-value="flag"
              hide-details
              color="primary"
              :label="$t(config.actionFlagLabel ?? 'devices.addAnother')"
              @update:model-value="updateActionFlag"
            />
            <v-spacer />
            <template
              v-for="button in config.buttonConfig"
              :key="button.callbackID"
            >
              <v-btn
                :variant="button.outlined ? 'outlined' : 'flat'"
                color="primary"
                :disabled="
                  processAction ||
                  (button.callbackID !== ButtonCallbacks.CANCEL
                    ? !mainActionActive
                    : false)
                "
                :class="flag ? 'text-none ma-1' : 'text-none ml-1 my-4'"
                :data-cy="'dialog-action-' + button.text.replaceAll('.', '-')"
                @click="clickButton(button.callbackID)"
              >
                <v-icon v-if="button.icon" class="mr-1">
                  {{ button.icon }}</v-icon
                >
                {{ $t(button.text) }}
              </v-btn>
            </template>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ButtonCallbacks } from '@/components/types/Dialog';
import { Dialog } from '../../types/Dialog';

export default defineComponent({
  name: 'ExtendedDialog',
  props: {
    showDialog: {
      type: Boolean,
      required: true
    },
    simpleInformationDialog: {
      type: Boolean,
      default: false
    },
    processAction: {
      type: Boolean,
      default: false
    },
    actionFlag: {
      type: Boolean,
      default: false
    },
    config: {
      type: Object as () => Dialog,
      required: true
    },
    titleCounter: {
      type: Number,
      default: 1
    },
    titleMsg: {
      type: String,
      default: undefined
    },
    overrideWidth: {
      type: String,
      default: undefined
    },
    mainActionActive: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update:showDialog', 'update:actionFlag', 'executeAction'],
  data() {
    return {
      ButtonCallbacks,
      flag: this.actionFlag
    };
  },
  computed: {
    show: {
      get() {
        return this.showDialog;
      },
      set(value) {
        this.$emit('update:showDialog', value);
      }
    }
  },
  methods: {
    clickButton(callbackID) {
      this.$emit('executeAction', callbackID);
    },
    closeDialog() {
      this.$emit('executeAction', ButtonCallbacks.CANCEL);
    },
    updateActionFlag(value) {
      this.$emit('update:actionFlag', value);
    }
  }
});
</script>
