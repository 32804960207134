
import axios from 'axios';
import { TokenService } from './auth/TokenService';
import { showSnackbar } from '@/util/snackBarUtil';
import { i18n } from '@/plugins/i18n';
import { authenticationStore } from '@/store/pinia/AuthenticationStore';
import router from '@/router';
const baseURL = import.meta.env.VITE_API_REFRESH_URL as string;
const refreshToken = TokenService.getRefreshToken() ?? '';

const axiosRefreshInstance = axios.create({
  baseURL: baseURL,
  headers: {
    Authorization: `Bearer ${refreshToken}`
  }
});

const RefreshService = {
  getNewAccessToken:async () => {
    const requestData = {
      access: TokenService.getAccessToken(),
      refresh: TokenService.getRefreshToken()
    };
    if ( requestData.refresh !== '') {
      try {
        const response = await axiosRefreshInstance.post('', requestData);
        TokenService.saveAccessToken(response.data.access);
        TokenService.saveRefreshToken(response.data.refresh);
        return response.data.access;
      } catch (error) {
        await RefreshService.cleanUpAndRedirectToLogin();
        throw error;
      }
    } else {
      await RefreshService.cleanUpAndRedirectToLogin();
    }
  },

  cleanUpAndRedirectToLogin:async () => {
    const authStore = authenticationStore();
    authStore.resetStore();
    TokenService.removeAccessToken();
    TokenService.removeRefreshToken();
    showSnackbar(i18n.t('errors.unauthorized'));
    await router.push('/login');
  }

};
export default RefreshService;
