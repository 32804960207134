<template>
  <v-navigation-drawer
    v-if="loggedIn"
    rail-width="53"
    permanent
    :rail="!expandNavigationDrawer"
  >
    <v-list v-model:opened="openedMenus" open-strategy="multiple">
      <NavigationGroup
        :navigation-items="navigationDrawerConfig"
        :expand-navigation-drawer="expandNavigationDrawer"
      />
    </v-list>
    <template #append>
      <v-row no-gutters justify="end" align="center" class="white-bg">
        <div class="feedback ml-3 pointer-cursor">
          <a
            v-if="expandNavigationDrawer"
            data-cy="feedback-button"
            class="text-blue"
            @click="openFeedbackDialog"
          >
            <v-icon size="small" color="primary" class="mr-2 pointer-cursor"
              >mdi-message-processing
            </v-icon>
            {{ $t('app.giveFeedback') }}</a
          >
        </div>
        <v-spacer />
        <v-btn
          id="app-navigation-toggle"
          size="small"
          class="ma-0 pa-0"
          variant="text"
          icon
          @click="expandNavigationDrawer = !expandNavigationDrawer"
        >
          <v-icon v-if="expandNavigationDrawer">mdi-chevron-double-left</v-icon>
          <v-icon v-if="!expandNavigationDrawer"
            >mdi-chevron-double-right</v-icon
          >
        </v-btn>
      </v-row>
    </template>
    <FeedbackDialog
      :show-dialog="showDialog"
      @update:show-dialog="showDialog = false"
    />
  </v-navigation-drawer>
</template>

<script lang="ts">
import NavigationGroup from './NavigationGroup.vue';
import { mapState } from 'pinia';
import { authenticationStore } from '@/store/pinia/AuthenticationStore';
import {
  navigationDrawerConfig,
  defaultOpenedMenus
} from '@/navigationDrawer/navigationDrawer';
import FeedbackDialog from '@/components/app/appBar/FeedbackDialog.vue';
import { defineComponent } from 'vue';

const OPEN_NAV_GROUPS = 'opened_navigation_groups';

export default defineComponent({
  components: { NavigationGroup, FeedbackDialog },

  data() {
    return {
      expandNavigationDrawer: true,
      width: '16.875em',
      showDialog: false,
      navigationDrawerConfig,
      openedMenus: [] as string[]
    };
  },
  computed: {
    ...mapState(authenticationStore, ['loggedIn']),
    navDrawerConfig() {
      return navigationDrawerConfig;
    }
  },
  watch: {
    openedMenus(val) {
      localStorage.setItem(OPEN_NAV_GROUPS, JSON.stringify(val));
    }
  },
  beforeMount() {
    const storedNavigationState = localStorage.getItem(OPEN_NAV_GROUPS);
    if (storedNavigationState) {
      this.openedMenus = JSON.parse(storedNavigationState) as string[];
    } else {
      localStorage.setItem(OPEN_NAV_GROUPS, JSON.stringify(defaultOpenedMenus));
      this.openedMenus = defaultOpenedMenus;
    }
  },

  methods: {
    openFeedbackDialog() {
      this.showDialog = true;
    }
  }
});
</script>
<style lang="scss" scoped>
.feedback {
  font-size: 14px;
  display: flex !important;
}
</style>
