<template>
  <div v-if="expandNavigationDrawer">
    <template v-for="item in navigationItems" :key="item.title">
      <v-list-item
        v-if="!item.children && isNavigationItem(item) && isVisible(item)"
        :active="currentPath.includes(item.path)"
        :title="$t(item.title)"
        :prepend-icon="item.icon"
        :value="item"
        color="primary"
        @click="$router.push(item.path)"
      />
      <v-list-group
        v-if="item.children && isNavigationGroup(item)"
        :value="item.title"
      >
        <template #activator="{ props }">
          <v-list-item
            :title="$t(item.title)"
            :prepend-icon="item.icon"
            v-bind="props"
            :value="item"
          >
          </v-list-item>
        </template>
        <NavigationGroup
          :navigation-items="item.children"
          :expand-navigation-drawer="true"
        />
      </v-list-group>
    </template>
  </div>

  <!-- collapsed NavigationDrawer-->
  <div v-else>
    <template v-for="item in navigationItems" :key="item.title">
      <v-menu open-on-hover location="right" close-on-content-click>
        <template #activator="{ props }">
          <v-list-item
            v-if="isNavigationGroup(item) || isNavigationItem(item)"
            :active="currentPathIncludesItemOrChild(item)"
            :title="$t(item.title)"
            :prepend-icon="item.icon"
            :value="item"
            color="primary"
            v-bind="props"
          />
        </template>
        <v-list v-if="item.children">
          <template v-for="(child, index) in item.children" :key="index">
            <v-list-item
              v-if="isVisible(child)"
              :active="currentPath.includes(child.path)"
              :title="$t(child.title)"
              color="primary"
              @click="$router.push(child.path)"
            />
          </template>
        </v-list>
        <v-list v-else>
          <v-list-item
            :active="currentPath.includes(item.path)"
            :title="$t(item.title)"
            color="primary"
            @click="$router.push(item.path)"
          />
        </v-list>
      </v-menu>
    </template>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import {
  NavigationItem,
  navigationItemVisible
} from '../../../navigationDrawer/navigationDrawer';
import { FeatureFlags } from '@/store/types/auth/index';
import { mapState } from 'pinia';
import { mapGetters } from 'vuex';
import { authenticationStore } from '@/store/pinia/AuthenticationStore';

const NavigationGroup = defineComponent({
  name: 'NavigationGroup',
  props: {
    navigationItems: {
      type: Array<NavigationItem>,
      required: true
    },
    expandNavigationDrawer: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      currentPath: this.$route.path
    };
  },
  computed: {
    ...mapState(authenticationStore, [
      'licensedFeatures',
      'isAdminAccount',
      'showNotHomeAccountBar'
    ]),
    ...mapGetters({ onPremises: 'onpremises' }),
    navigationItemVisible(): typeof navigationItemVisible {
      return navigationItemVisible;
    }
  },
  watch: {
    $route: function (to) {
      this.currentPath = to.path;
    }
  },
  methods: {
    currentPathIncludesItemOrChild(item: NavigationItem) {
      if (item.children) {
        return item.children.some((child) =>
          this.currentPath.includes(child.path)
        );
      } else {
        return this.currentPath.includes(item.path);
      }
    },

    isNavigationItem(item: NavigationItem): boolean {
      return (
        !!item.path && this.checkVisibilityDependingOnLicensedFeatures(item)
      );
    },

    isNavigationGroup(item: NavigationItem): boolean {
      return (
        !item.path && this.checkVisibilityDependingOnLicensedFeatures(item)
      );
    },
    checkVisibilityDependingOnLicensedFeatures(item: NavigationItem): boolean {
      return navigationItemVisible(
        item.featureFlags,
        {
          ...(this.licensedFeatures as FeatureFlags),
          isAdminAccount: this.isAdminAccount,
          isHomeAccount: !this.showNotHomeAccountBar
        },
        item.featureFlagAxiom
      );
    },
    isVisible(item: NavigationItem): boolean {
      return (
        this.isVisibleOnCurrentSystemType(item) &&
        this.checkVisibilityDependingOnLicensedFeatures(item)
      );
    },
    isVisibleOnCurrentSystemType(item: NavigationItem): boolean {
      if (this.onPremises) return item.onlyIfCloud ? !item.onlyIfCloud : true;
      else return item.onlyIfCloud ?? true;
    }
  }
});
export default NavigationGroup;
</script>
