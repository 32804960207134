import { FeatureFlags } from '../store/types/auth/index';
export type NavigationItem = {
  title: string;
  icon?: string;
  featureFlags?: keyof FeatureFlags | (keyof FeatureFlags)[];
  onlyIfCloud?: boolean;
  featureFlagAxiom?: 'AND' | 'OR';
  callback?: () => void;
} & (
  | { path: string; children?: never }
  | { children: NavigationItem[]; path?: never }
  | { callback: () => void; children?: never; path?: never }
);

export interface NavigationMenu {
  items: NavigationItem[];
  title: string;
  icon: string;
}

export const navigationItemVisible = (
  flagConditions: NavigationItem['featureFlags'],
  featureFlags: FeatureFlags,
  featureFlagAxiom?: 'AND' | 'OR'
): boolean => {
  if (!flagConditions) {
    return true;
  }
  if (typeof flagConditions === 'string') {
    flagConditions = [flagConditions];
  }
  if (featureFlagAxiom === 'AND') {
    return flagConditions.every((flagCondition) => featureFlags[flagCondition]);
  }

  return flagConditions.some((flagCondition) => featureFlags[flagCondition]);
};

export const navigationDrawerConfig: NavigationItem[] = [
  { title: 'routes.dashboard', path: '/dashboard', icon: 'mdi-view-dashboard' },
  {
    title: 'routes.inventory',
    icon: 'mdi-package-variant-closed',
    children: [
      { title: 'routes.routers', path: '/devices' },
      { title: 'routes.deviceGroups', path: '/device-groups' },
      {
        title: 'routes.configChangeTemplates',
        path: '/config-change-templates',
        featureFlags: 'cliTemplatesEnabled'
      }
    ]
  },
  {
    title: 'common.updates',
    icon: 'mdi-update',
    featureFlags: ['autoUpdateServerEnabled', 'updateJobsEnabled'],
    children: [
      { title: 'routes.autoUpdateServer', path: '/update-server', featureFlags: 'autoUpdateServerEnabled' },
      { title: 'routes.smartUpdate', path: '/smart-update', onlyIfCloud: true },
      { title: 'routes.updateJobs', path: '/update-jobs', featureFlags: 'updateJobsEnabled' },
      { title: 'routes.updatePackages', path: '/update-packages', featureFlags: 'updatePacketManagementEnabled' },
      { title: 'common.resources', path: '/resources', featureFlags: 'updatePacketManagementEnabled' }
    ]
  },
  {
    title: 'routes.certificate-management',
    icon: 'mdi-shield-key',
    featureFlags: 'certificateManagementEnabled',
    children: [
      {
        title: 'routes.certificate-authorities',
        path: '/certificate-authorities'
      },
      { title: 'routes.certificates', path: '/certificates' }
    ]
  },
  {
    title: 'routes.auditLogs',
    path: '/audit-logs',
    icon: 'mdi-text-box-search',
    featureFlags: 'auditLogEnabled'
  },
  {
    title: 'administration.administration',
    icon: 'mdi-wrench',
    featureFlags: ['userManagementEnabled', 'isAdminAccount', 'isHomeAccount'],
    featureFlagAxiom: 'AND',
    children: [
      {
        title: 'administration.systemInfo',
        path: '/administration/system-information'
      },
      {
        title: 'administration.accounts',
        path: '/administration/user-and-account-management/accounts'
      },
      {
        title: 'common.user',
        path: '/administration/user-and-account-management/users'
      },
      {
        title: 'administration.organisation',
        path: '/administration/user-and-account-management/organisations'
      },
      {
        title: 'administration.systemSettings',
        path: '/administration/system-settings'
      }
    ]
  }
];

export const defaultOpenedMenus: string[] = ['routes.inventory', 'common.updates'];
